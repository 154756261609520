<template>
  <el-row class="warp">
    <el-col :span="24">
      <el-form :inline="true" :model="searchForm" size="small">
        <el-form-item>
          <el-input v-model="searchForm.projectName" placeholder="请输入项目名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
          <el-button type="primary" @click="handleReset">重置</el-button>
          <el-button type="primary" @click="showAddEditDialog()">添加</el-button>
        </el-form-item>
      </el-form>

      <!--列表-->
      <el-table :data="listData">
        <el-table-column type="index" width="60"></el-table-column>
        <el-table-column prop="projectName" label="项目名称"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="160"></el-table-column>
        <el-table-column label="操作" fixed="right" width="180">
          <template slot-scope="scope">
            <el-button size="small" @click="showAddEditDialog(scope.row)">编辑</el-button>
            <el-button size="small" type="danger" @click="delData(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!--工具条-->
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="10"
        :page-sizes="[10, 20, 30, 50]"
        :total="total"
        style="float:right;"
      >
      </el-pagination>

      <!--新增编辑界面-->
      <el-dialog :title="addEditTitle" :visible.sync="addEditFormVisible" :close-on-click-modal="false">
        <el-form :model="addEditForm" label-width="80px" :rules="addEditFormRules" ref="addEditForm">
          <el-form-item label="项目名" prop="name">
            <el-input v-model="addEditForm.projectName"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="addEditFormVisible = false" size="small">取消</el-button>
          <el-button type="primary" @click.native="addEditSubmit" size="small">保存</el-button>
        </div>
      </el-dialog>
    </el-col>
  </el-row>
</template>

<script>
import API from '@/api/system/api_config'
import PagesMixins from '@/mixins/pagesMixins.js'
import _ from 'lodash'
const searchForm = {
  projectName: ''
}
const addEditForm = {
  projectName: ''
}
export default {
  mixins: [PagesMixins],
  data() {
    return {
      searchForm: _.cloneDeep(searchForm),
      listData: [],

      addEditTitle: '',
      addEditType: '',
      addEditForm: _.cloneDeep(addEditForm),
      addEditFormVisible: false,
      addEditFormRules: {
        projectName: [
          { required: true, message: '请输入项目名称', trigger: 'blur' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.handleSearch()
  },
  methods: {
    handleSearch() {
      this.total = 0
      this.page = 1
      this.getListData()
    },
    handleReset() {
      this.searchForm = _.cloneDeep(searchForm)
      this.handleSearch()
    },
    getListData() {
      let params = {
        page: this.page,
        size: this.size,
        ...this.searchForm
      }
      API.queryProjectList(params).then(result => {
        if (result && result.code === '000000') {
          this.total = result.total
          this.listData = result.data.list
        }
      })
    },
    showAddEditDialog(row) {
      if (this.$refs.addEditForm !== undefined) {
        this.$refs.addEditForm.resetFields()
      }
      if (row) {
        this.addEditTitle = '编辑'
        this.addEditType = 'edit'
        this.addEditForm = _.cloneDeep(row)
      } else {
        this.addEditTitle = '新增'
        this.addEditType = 'add'
        this.addEditForm = _.cloneDeep(addEditForm)
      }
      this.addEditFormVisible = true
    },
    //新增
    addEditSubmit() {
      this.$refs.addEditForm.validate(valid => {
        if (valid) {
          let params = Object.assign({}, this.addEditForm)
          let response
          if (this.addEditType === 'add') {
            response = API.addProject(params)
          } else {
            response = API.editProject(params)
          }
          response.then(result => {
            if (result && result.code === '000000') {
              this.$message.success({ showClose: true, message: this.addEditType === 'add' ? '新增成功' : '编辑成功', duration: 2000 })
              this.addEditFormVisible = false
              this.getListData()
            } else {
              this.$message.error({ showClose: true, message: result.msg, duration: 2000 })
            }
          })
        }
      })
    },
    //删除
    delData(index, row) {
      this.$confirm('确认删除该记录吗?', '提示', { type: 'warning' }).then(() => {
        let args = {
          projectId: row.projectId
        }
        API.delProject(args).then(result => {
          if (result && result.code === '000000') {
            this.$message.success({ showClose: true, message: '删除成功', duration: 1500 })
            this.getListData()
          }
        })
      })
    }
  }
}
</script>
