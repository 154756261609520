import * as http from '../http'

export default {
  //获取列表
  queryProjectList: params => {
    return http.post('/config/project/list', params)
  },
  queryConfigItemList: params => {
    return http.post('/config/config-item/List', params)
  },
  //获取所有项目
  queryProjectAll: params => {
    return http.post('/config/project-option/list', params)
  },

  //获取列表
  addProject: params => {
    return http.post('/config/project/add', params)
  },
  //获取列表
  editProject: params => {
    return http.post('/config/project/update', params)
  },
  //获取列表
  delProject: params => {
    return http.post('/config/project/delete', params)
  },
  //获取列表
  queryConfigList: params => {
    return http.post('/config/config/list', params)
  },
  //获取列表
  addConfig: params => {
    return http.post('/config/config/add', params)
  },
  //获取列表
  editConfig: params => {
    return http.post('/config/config/update', params)
  },
  //删除父KEY
  delConfig: params => {
    return http.post('/config/config/delete', params)
  },
  //删除子KEY
  delConfigItem: params => {
    return http.post('/config/config-item/delete', params)
  },
  updateCach: params => {
    return http.post('/config/cache/fresh', params)
  },
  addConfigItem: params => {
    return http.post('/config/config-item/add', params)
  }
}
